/* eslint-disable no-undef */
<template>
  <page-container type="fullframe">
    <div class="relative bg-white h-100-p">
      <split-pane split-to="columns" :size="330" :allow-resize="true">
        <template slot="firstPane">
          <resize-wrap class="h-100-p">
            <template v-slot="{height}">
              <virtual-tree
                class="p-10"
                v-loading="loading"
                ref="treeRef"
                @node-bdlclick="nodeDbclick"
                :height="height"
                :props="{id:'treeId'}"
              >
                <template v-slot:prefix="{item}">
                  <img v-if="item.type==3" src="@/assets/imgs/common/tree_icon_online.png">
                  <i v-else-if="item.type==4" class="iconfont icon-shipin blue"></i>
                </template>
                <template v-slot:prefixLeaf="{item}">
                  <img v-if="item.type==3" src="@/assets/imgs/common/tree_icon_online.png">
                  <i v-else-if="item.type==4" class="iconfont icon-shipin blue"></i>
                </template>
              </virtual-tree>
            </template>
          </resize-wrap>
        </template>

        <template slot="secondPane">
          <div class="flex flex-column h-100-p">
            <div class="p-10 fs-14 flex align-center justify-between">
              实时视频
              <div>
                <i class="el-icon-video-play" title="轮播设置" style="margin-left: 10px;cursor: pointer;font-size: 18px" @click="videoWheelPlaySetDialog = true"/>
                <i class="el-icon-close" title="全部关闭" style="margin-left: 10px;cursor: pointer;font-size: 18px" @click="closeAllPLayer"/>
                <i class="el-icon-full-screen" title="视频全屏" style="margin-left: 10px;cursor: pointer;font-size: 18px" @click="videoFullScreen"/>
                <i class="el-icon-s-grid" title="画面设置" style="margin-left: 10px;margin-right: 10px;cursor: pointer;font-size: 18px" @click="videoWindowSetDialog=true"/>
              </div>
            </div>

            <div style="flex: 1;height: 0;width: 100%;display: flex;flex-direction: column;overflow: hidden" id="videoWindow">
              <div style="flex: 1;height: 0;width: 100%;display: flex;border-bottom: 0 solid white" v-for="rowIndex in videoRows" :key="'row'+rowIndex">
                <div v-for="colIndex in videoColumns" :key="colIndex" class="video-row">
                  <div class="video-normal" :class="[currentVideoIndex===((rowIndex-1)*videoColumns)+colIndex-1?'video-selected':'video-normal']" @click="onVideoDivSelected(((rowIndex-1)*videoColumns)+colIndex-1)" :id="videoId(((rowIndex-1)*videoColumns)+colIndex-1)">
                    <img src="@/assets/imgs/monitor/video_cover.png" style="width: 100%;height: 100%">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <el-dialog
            title="视频窗口设置"
            :visible.sync="videoWindowSetDialog"
            :append-to-body="true"
            v-dialogDrag
            width="500px">
            <el-form ref="form" :model="videoWindow" label-width="180px" size="small" style="margin-top: 10px">
              <el-form-item label="行数">
                <el-input-number v-model="videoWindow.rows" :min="1" :max="5" placeholder="行数"/>
              </el-form-item>
              <el-form-item label="列数">
                <el-input-number v-model="videoWindow.columns" :min="1" :max="5" placeholder="列数"/>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="videoWindowSetDialog = false" size="mini">取消</el-button>
              <el-button type="primary" @click="videoWindowSet" size="mini">确定</el-button>
            </div>
          </el-dialog>

          <el-dialog
            title="视频轮播设置"
            :visible.sync="videoWheelPlaySetDialog"
            :append-to-body="true"
            v-dialogDrag
            width="450px">
            <el-form ref="form" :model="videoWheel" size="mini" :rules="videoWheelRules" label-width="100px" style="margin-top: 10px">
              <el-form-item label="轮播通道:" prop="channels">
                <el-checkbox-group v-model="videoWheel.channels">
                  <el-checkbox label="通道1" name="channels"/>
                  <el-checkbox label="通道2" name="channels"/>
                  <el-checkbox label="通道3" name="channels"/>
                  <el-checkbox label="通道4" name="channels"/>
                  <el-checkbox label="通道5" name="channels"/>
                  <el-checkbox label="通道6" name="channels"/>
                  <el-checkbox label="通道7" name="channels"/>
                  <el-checkbox label="通道8" name="channels"/>
                  <el-checkbox label="通道9" name="channels"/>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="轮播时间:">
                <el-input-number v-model="videoWheel.playTime" :min="10" :max="300"/>
                <span style="margin-left: 10px">秒</span>
              </el-form-item>
              <el-form-item label="轮播次数:">
                <el-input-number v-model="videoWheel.wheelCount" :min="1" :max="10"/>
                <span style="margin-left: 10px">次</span>
              </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="videoWheelPlaySetDialog = false" size="mini">取消</el-button>
              <el-button type="primary" @click="videoWheelPlaySet()" size="mini">确定</el-button>
            </div>
          </el-dialog>
        </template>
      </split-pane>
    </div>
  </page-container>
</template>
<script>
import SplitPane from '@/components/SplitPane'
import VirtualTree from '@/components/VirtualTree'
import { getVediTree } from '@/apis/monitor/carVideo'
import screenfull from 'screenfull'
import XEUtils from 'xe-utils'
export default {
  data () {
    return {
      loading: false,
      treeData: [],
      busTreeId: null,
      treeCallback: {
        onDblClick: this.zTreeOnDblClick
      },
      currentVideoIndex: 0,
      videoRows: 3,
      videoColumns: 3,
      videoWindowSetDialog: false,
      videoWindow: {
        rows: 3,
        columns: 3
      },
      videoPlayers: new Map(),
      videoWheelPlaySetDialog: false,
      videoWheel: {
        channels: ['通道1'],
        playTime: 60,
        wheelCount: 1
      },
      videoWheelRules: {
        channels: [
          { type: 'array', required: true, message: '请至少选择一个通道', trigger: 'change' }
        ]
      },
      wheelBusChannelNodes: [],
      wheelVideoIndex: 0,
      wheelCounted: 0,
      wheelTimer: null,
      wheelSourceMap: new Map(),
      wheelChnnelTimer: [],
      // videoServer: this.$http.videoServer,
      videoServer: '',
      playTime: 180,
      playerTitleMap: new Map()
    }
  },
  computed: {
    // 机构 或 企业的 id
    companyParams () {
      const userInfo = this.$store.state.account.userInfo
      const params = {}
      params.compId = userInfo.compId || undefined
      params.orgId = userInfo.orgaId || undefined
      return params
    }
  },
  created () {
    getVediTree().then((res) => {
      if (res.code == 200) {
        const data = res.data
        const list = []
        data.companyNodeList.map((item) => {
          item.treeId = 'COMP_' + item.id
          item.pId = '0'
          if (data.fieldNodeList.some(field => field.pid == item.id)) {
            list.push(item)
          }
          return item
        })
        data.fieldNodeList.map((item) => {
          item.treeId = 'FIELD_' + item.id
          item.pId = 'COMP_' + item.pid
          const videoUrl = 'https://media.wazert.com' // 网泽车载视频服务器地址
          item.mediaDomain = item.mediaDomain || videoUrl
          list.push(item)
          return item
        })
        data.channelList.map((item, index) => {
          item.treeId = 'CHANNEL_' + index
          item.pId = 'FIELD_' + item.pid
          list.push(item)
          return item
        })
        if (list.length > 0) list.unshift({ name: '全部视频', treeId: '0' })

        const treeData = this.treeData = XEUtils.toArrayTree(list, {
          key: 'treeId',
          parentKey: 'pId'
        })

        console.log(treeData, 'treeData')
        // 初始化树组件
        this.$refs.treeRef.loadTree(treeData)
        // 选中全部车辆
        this.$refs.treeRef.toogleNodeCheck('0', true)
        // 展开全部车辆
        this.$refs.treeRef.setTreeExpand('0', true)
      } else {
        this.$message.error(res.msg)
      }
    })
  },
  methods: {
    nodeDbclick (treeNode) {
      if (!treeNode) return
      let flvSource

      if (treeNode.type === 4) { // type=4 通道
      // 如果点击的是通道，判断父节点的acc是否为false，若为false，提示...
        const matchObj = XEUtils.findTree(this.treeData, item => item.treeId === treeNode.pId)
        const busNode = matchObj.item // 设备
        // 获取视频地址
        flvSource = `${busNode.mediaDomain}/video?UserID=${busNode.userID}_BusID=${busNode.id}_Channel=${treeNode.id}_CodeType=1_Operate=1_PlayTime=120`
        // 生成视频名称
        const name = busNode.name + '-' + treeNode.name
        this.startPlayVideo(flvSource, name)
      }

      if (treeNode.type === 3) { // type = 3 设备
        const channelNodes = treeNode.children

        for (let i = 0; i < channelNodes.length; i++) {
          flvSource = `${treeNode.mediaDomain}/video?UserID=${treeNode.userID}_BusID=${treeNode.id}_Channel=${channelNodes[i].id}_CodeType=1_Operate=1_PlayTime=18060`
          const name = treeNode.name + '-' + channelNodes[i].name
          this.startPlayVideo(flvSource, name)
        }
      }
    },

    startPlayVideo (flvSource, title) {
      const closeButton = videojs.getComponent('Button')
      const _this = this
      const MyButton = videojs.extend(closeButton, {
        constructor: function () {
          closeButton.apply(this, arguments)
          this.controlText('关闭')
        },
        handleClick: function () {
          _this.clsoePlayer(this.player_.id_)
        },
        buildCSSClass: function () {
          return 'vjs-icon-closebutton vjs-control vjs-button'
        }
      })
      videojs.registerComponent('MyButton', MyButton)
      const videoId = 'videoId_' + this.currentVideoIndex
      this.playerTitleMap.set(this.currentVideoIndex, title)

      if (this.videoPlayers.get(videoId)) {
        try {
          this.videoPlayers.get(videoId).dispose()
        } catch (e) {
          console.log(e)
        }
      }

      const videoHtml = '<video id=' + videoId + ' class="video-js vjs-default-skin vjs-big-play-centered" style="object-fit: fill;width: 100%;height: 100%"  controls preload="auto" autoplay="autoplay" muted="muted" width="100%" height="100%"></video>'
      document.getElementById('videoElement_' + this.currentVideoIndex).innerHTML = videoHtml

      const options = {
        poster: require('@/assets/imgs/monitor/video_cover.png'),
        autoplay: true,
        fill: true,
        liveui: true,
        language: 'zh-CN',
        flvjs: {
          mediaDataSource: {
            isLive: true,
            cors: true,
            withCredentials: false,
            lazyLoad: false,
            enableStashBuffer: false,
            enableWorker: true,
            stashInitialSize: 1,
            fixAudioTimestampGap: false
          }
        },
        controlBar: {
          progressControl: false,
          remainingTimeDisplay: false,
          volumePanel: {
            inline: false
          }
        }
      }

      const player = videojs(videoId, options, function onPlayerReady () {
        this.src([
          // {type : "rtmp/flv",src: flvSource} //rtmp
          // {type : "application/x-mpegURL",src: hlsSource} //HLS
          { type: 'video/x-flv', src: flvSource } // Flv
          // {type: "video/mp4", src: flvSource} //Flv
        ])
        this.getChild('controlBar').addChild('myButton', {})
        this.load()
        this.play()
      })
      this.videoPlayers.set(videoId, player)
      player.on('loadstart', function (e) {
        const flvPlayer = this.tech_.flvPlayer
        this.play()
        setTimeout(() => {
          this.play()
        }, 500)
        var _this_ = _this
        flvPlayer.on(flvjs.Events.ERROR, function (e) {
          var pid = flvPlayer._mediaElement.id
          pid = pid.replace('_flvjs_api', '')
          var pidIndex = pid.replace('videoId_', '')
          if (e === 'NetworkError') {
            var errorPlayer = _this_.videoPlayers.get(pid)
            if (errorPlayer) {
              errorPlayer.pause()
              var videoEl = errorPlayer.el()
              var div = document.createElement('div')
              div.classList.add('networkerror-display')
              div.innerHTML = '离线/信号不好...'
              videoEl.appendChild(div)
            }
          }

          if (e === 'MediaError') {
            // 重新播放
            var sourceUrl = flvPlayer._mediaDataSource.url
            _this_.currentVideoIndex = parseInt(pidIndex)
            var titleEe = _this_.playerTitleMap.get(parseInt(pidIndex))
            _this_.startPlayVideo(sourceUrl, titleEe)
          }
        })
      })

      if (title) {
        const videoEl = player.el()
        const div = document.createElement('div')
        div.classList.add('vjs-watermark-content')
        div.classList.add('vjs-watermark-top-right')
        div.innerHTML = title
        videoEl.appendChild(div)
      }

      this.currentVideoIndex++

      if (this.currentVideoIndex === this.videoRows * this.videoColumns) {
        this.currentVideoIndex = 0
      }
    },

    videoId (index) {
      return 'videoElement_' + index
    },

    onVideoDivSelected (index) {
      this.currentVideoIndex = index
    },

    closeAllPLayer () {
      if (this.wheelTimer) {
        clearTimeout(this.wheelTimer)
        this.wheelTimer = null
      }
      this.wheelChnnelTimer.forEach(timer => {
        clearTimeout(timer)
        timer = null
      })
      this.wheelChnnelTimer = []

      const allPlayerCount = this.videoRows * this.videoColumns
      for (let i = 0; i < allPlayerCount; i++) {
        this.clsoePlayer('videoId_' + i)
      }
      this.currentVideoIndex = 0
    },

    clsoePlayer (videoId) {
      if (this.videoPlayers.get(videoId)) {
        try {
          this.videoPlayers.get(videoId).dispose()
        } catch (e) {
          console.log(e)
        }
        this.videoPlayers.set(videoId, null)
      } else {
      }
      const videoElementId = videoId.replace('videoId', 'videoElement')
      const imgHtml = '<img src="' + require('@/assets/imgs/monitor/video_cover.png') + '" style="width: 100%;height: 100%">'

      if (document.getElementById(videoElementId)) {
        document.getElementById(videoElementId).innerHTML = imgHtml
      }
    },

    videoWindowSet () {
      this.closeAllPLayer()
      this.currentVideoIndex = 0
      this.videoWindowSetDialog = false
      this.videoRows = this.videoWindow.rows
      this.videoColumns = this.videoWindow.columns
    },

    videoWheelPlaySet () {
      this.videoWheelPlaySetDialog = false
      this.wheelBusChannelNodes = []

      const checkedNodes = this.$refs.treeRef.getLeafCheckedNodes()
      console.log(this.checkedNodes, 'checkedNodes')

      if (checkedNodes) {
        checkedNodes.forEach(node => {
          if (node.type === 4) {
            // 把所有选中的车辆通道添加到轮询数组中;
            if (this.videoWheel.channels.includes(node.name)) {
              this.wheelBusChannelNodes.push(node)
            }
          }
        })
      }
      this.closeAllPLayer()
      this.currentVideoIndex = 0
      this.wheelVideoIndex = 0
      this.wheelCounted = 0
      this.startWheelPlay()
    },

    startWheelPlay () {
      let videoWindowNums = this.videoRows * this.videoColumns

      // 如果需要轮播的通道数小于当前设置的窗口数量;把播放窗口数设置为 需要轮询通道数;
      if (this.wheelBusChannelNodes.length < videoWindowNums) {
        videoWindowNums = this.wheelBusChannelNodes.length
      }
      // 开始轮询播放
      let hasPlayNum = 0

      for (let i = this.wheelVideoIndex; i < this.wheelBusChannelNodes.length; i++) {
        const channelNode = this.wheelBusChannelNodes[this.wheelVideoIndex]
        const matchObj = XEUtils.findTree(this.treeData, item => item.treeId === channelNode.pId)
        const busNode = matchObj.item
        // 获取视频地址
        const flvSource = `${busNode.mediaDomain}/video?UserID=${busNode.userID}_BusID=${busNode.id}_Channel=${channelNode.id}_CodeType=1_Operate=1_PlayTime=120`
        // 生成视频名称
        const name = busNode.name + '-' + channelNode.name
        this.wheelSourceMap.set(i, {
          flvSource: flvSource,
          title: name
        })
        const wheelChnnelTimer = setTimeout(() => {
          this.startPlayVideo(flvSource, name)
        }, hasPlayNum * 100)
        this.wheelChnnelTimer.push(wheelChnnelTimer)
        hasPlayNum++

        this.wheelVideoIndex++
        if (hasPlayNum === videoWindowNums) {
          break
        }
      }
      // 如果轮播索引等于 轮询数,开始下一次轮播
      if (this.wheelVideoIndex === this.wheelBusChannelNodes.length) {
        this.wheelVideoIndex = 0
        this.wheelCounted++
        // 如果轮播次数小于设置的轮播次数继续轮播
        if (this.wheelCounted < this.videoWheel.wheelCount) {
          const _this = this
          const timeOut = this.videoWheel.playTime * 1000 + 3000
          this.wheelTimer = setTimeout(function () {
            _this.startWheelPlay()
          }, timeOut)
        }
      } else {
        const _this = this
        const timeOut = this.videoWheel.playTime * 1000 + 3000
        this.wheelTimer = setTimeout(function () {
          _this.startWheelPlay()
        }, timeOut)
      }
    },

    videoFullScreen () {
      screenfull.toggle(document.getElementById('videoWindow'))
    }
  },
  components: {
    SplitPane,
    VirtualTree
  }
}
</script>

<style>
.video-js {
  width: 100%;
  height: 100%;
  object-fit: fill;
  box-sizing: border-box;
}

.vjs-tech {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.vjs-icon-custombutton {
  font-family: VideoJS;
  font-weight: normal;
  font-style: normal;
}

.vjs-icon-closebutton:before {
  content: "关闭";
}

.vjs-watermark-fade {
  opacity: 100
}

.networkerror-display {
  height: 30px;
  line-height: 30px;
  vertical-align: center;
  padding-left: 10px;
  font-size: 12px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 40px);
}
</style>

<style scoped>
.west-layout {
  width: 16%;
  min-width: 307px;
}

.center_title {
  display: flex;
  align-items: center;
  height: 30px;
  padding-left: 5px;
  padding-right: 5px;
  border-bottom: 1px solid #D3D3D3;
  background: #f3f3f3 linear-gradient(to bottom, #F8F8F8 0, #eeeeee 100%) repeat-x;
}

.video-normal {
  flex: 1;
  width: 0;
  height: 100%;
  display: block;
  object-fit: fill;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
  border-right: 1px #D3D3D3 solid;
}

.video-selected {
  flex: 1;
  width: 0;
  height: 100%;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
  border: 3px darkorange solid;
}

.video-row {
  flex: 1;
  height: 100%;
  width: 0;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
  border-bottom: 1px #D3D3D3 solid;
}
</style>
